"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addFavoriteIcon = exports.thePrice = exports.otherPrice = exports.productDescription = exports.productCard = exports.paginationLink = exports.paginationItem = exports.pagination = exports.listOld = exports.list = exports.display = exports.sort = exports.loading = exports.wrapper = exports.root = exports.modalStyle = exports.listStyle = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _color = _interopRequireDefault(require("color"));

var _media = require("../../constants/media");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var listStyle = function listStyle(colors, isGrid) {
  return {
    // ant-pagination
    '.ant-pagination-item': {
      lineHeight: '40px',
      height: '40px',
      width: '40px'
    },
    // ant-select
    '.ant-select-disabled .ant-select-selection': {
      borderColor: '#B3B3B3',
      color: '#5C5C5C',
      opacity: 0.4
    },
    '.ant-select-selection__rendered': {
      marginRight: '30px'
    },
    // ant-spin
    '.ant-spin': {
      width: '100%',
      height: '200px',
      background: 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '.ant-spin-dot i': {
      backgroundColor: colors[3]
    },
    // loaders
    mediaQueries: (0, _defineProperty2["default"])({}, _media.PHONE_MEDIA.substring(7), {
      // ant-pagination
      '.ant-pagination-item': {
        lineHeight: '32px',
        height: '32px',
        width: '32px'
      },
      '.loaders': {
        width: "".concat(isGrid ? '50%' : '100%', " !important"),
        margin: '0px !important',
        padding: '10px'
      }
    })
  };
};

exports.listStyle = listStyle;

var modalStyle = function modalStyle(colors) {
  return {
    // ant-modal
    padding: '0',
    '.ant-modal-content': {
      backgroundColor: colors[0],
      overflow: 'hidden'
    },
    '.ant-modal-header': {
      backgroundColor: 'transparent',
      borderBottom: 'none'
    },
    '.ant-modal-title': {
      textAlign: 'center',
      color: colors[3]
    },
    '.ant-modal-close': {
      color: colors[3]
    },
    '.ant-modal-body': {
      padding: '0',
      maxHeight: '80vh',
      overflow: 'auto',
      marginBottom: '50px'
    },
    mediaQueries: (0, _defineProperty2["default"])({}, _media.PHONE_MEDIA.substring(7), {
      top: '0',
      margin: '0',
      height: '100%',
      width: '100% !important',
      maxWidth: 'initial !important',
      '.ant-modal-content': {
        borderRadius: '0',
        height: '100%'
      },
      '.ant-modal-body': {
        borderRadius: '0',
        maxHeight: 'unset',
        height: 'calc(100% - 105px)',
        paddingBottom: '50px',
        marginBottom: '0'
      }
    })
  };
};

exports.modalStyle = modalStyle;
var root = {
  width: '100%',
  wordWrap: 'break-word',
  hyphens: 'auto',
  position: 'relative'
};
exports.root = root;
var wrapper = (0, _defineProperty2["default"])({
  padding: '24px 0 40px 0'
}, _media.PHONE_MEDIA, {
  padding: '16px 0 32px 0'
});
exports.wrapper = wrapper;
var loading = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  cursor: 'not-allowed'
};
exports.loading = loading;

var sort = function sort(showSort) {
  return (0, _defineProperty2["default"])({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: showSort ? '0 0 16px 0' : '0px'
  }, _media.PHONE_MEDIA, {
    padding: '16px 20px 12px 20px',
    justifyContent: 'space-between'
  });
};

exports.sort = sort;
var display = (0, _defineProperty2["default"])({
  width: '40px',
  height: '40px',
  fontSize: '16px',
  display: 'none',
  marginLeft: 'auto',
  cursor: 'pointer'
}, _media.PHONE_MEDIA, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'var(--color--1)',
  borderRadius: '4px',
  border: '1px solid var(--color-6)'
});
exports.display = display;

var list = function list(rowWidth, rowNum, isGrid) {
  return (0, _defineProperty2["default"])({
    display: 'grid',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    gridTemplateColumns: rowWidth ? "repeat(".concat(rowNum, ",minmax(0px,").concat(rowWidth, "px))") : "repeat(".concat(rowNum, ",minmax(0px,1fr))")
  }, _media.PHONE_MEDIA, {
    gridTemplateColumns: "repeat(".concat(isGrid ? '2, minmax(0px,50%)' : '1, minmax(0px,1fr)', ")"),
    gap: '32px 20px',
    padding: '0 20px'
  });
};

exports.list = list;

var listOld = function listOld(padding) {
  return (0, _defineProperty2["default"])({
    display: 'flex',
    flexWrap: 'wrap',
    padding: padding / 2
  }, _media.PHONE_MEDIA, {
    padding: '0px'
  });
};

exports.listOld = listOld;
var pagination = (0, _defineProperty2["default"])({
  display: 'flex',
  justifyContent: 'center',
  padding: '40px 0 0 0'
}, _media.PHONE_MEDIA, {
  padding: '32px 0 0 0'
});
exports.pagination = pagination;

var paginationItem = function paginationItem(colors, current) {
  if (current) {
    return {
      color: 'var(--color-7)'
    };
  }

  return {
    color: 'var(--color-4)'
  };
};

exports.paginationItem = paginationItem;
var paginationLink = {
  width: '100%',
  margin: '0'
};
exports.paginationLink = paginationLink;

var productCard = function productCard(colors, isGrid) {
  return (0, _defineProperty2["default"])({
    color: colors[3]
  }, _media.PHONE_MEDIA, {
    width: isGrid ? '50%' : '100%',
    margin: '0',
    padding: '10px'
  });
};

exports.productCard = productCard;

var productDescription = function productDescription(colors) {
  return {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    lineHeight: '1.5em',
    overflow: 'hidden',
    fontSize: '14px',
    marginBottom: '6px',
    color: (0, _color["default"])(colors[3]).alpha(0.8),
    maxHeight: 42
  };
};

exports.productDescription = productDescription;

var otherPrice = function otherPrice(colors) {
  return {
    fontSize: '12px',
    marginBottom: '2px',
    color: (0, _color["default"])(colors[3]).alpha(0.6)
  };
};

exports.otherPrice = otherPrice;
var thePrice = {
  fontSize: '16px',
  fontWeight: '700'
};
exports.thePrice = thePrice;

var addFavoriteIcon = function addFavoriteIcon() {
  var listTemplate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'style-4';
  var style = {};

  switch (listTemplate) {
    case 'style-1':
    case 'style-3':
      style = (0, _defineProperty2["default"])({
        color: '#fff',
        background: 'transparent',
        right: '12px',
        top: '12px'
      }, _media.PHONE_MEDIA, {
        right: '8px',
        top: '8px'
      });
      break;

    case 'style-2':
      style = (0, _defineProperty2["default"])({
        color: '#fff',
        background: 'transparent',
        right: '12px',
        bottom: '12px'
      }, _media.PHONE_MEDIA, {
        right: '8px',
        bottom: '8px'
      });
      break;

    case 'style-4':
      style = (0, _defineProperty2["default"])({
        color: 'var(--color-4)',
        background: 'var(--color-1)',
        right: '12px',
        bottom: '12px',
        width: '38px',
        height: '38px',
        padding: '10px',
        boxShadow: '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)'
      }, _media.PHONE_MEDIA, {
        bottom: '8px',
        right: '8px'
      });
      break;

    case 'style-5':
      style = (0, _defineProperty2["default"])({
        color: '#fff',
        background: 'transparent',
        right: '12px',
        bottom: '12px'
      }, _media.PHONE_MEDIA, {
        right: '8px',
        bottom: '8px'
      });
      break;

    default:
      break;
  }

  return _objectSpread({
    padding: 0,
    borderRadius: '50%',
    border: 0,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    cursor: 'pointer',
    fontSize: '16px',
    zIndex: 1
  }, style);
};

exports.addFavoriteIcon = addFavoriteIcon;